.reactions {
  background-color: var(--slate-a3);
  color: var(--slate-a11);
  border-radius: max(var(--radius-1), var(--radius-full));
  display: flex;
  align-items: center;
  padding: calc(var(--space-1) / 2) var(--space-2);
  line-height: var(--line-height-1);

  img {
    max-height: var(--space-4);
    max-width: var(--space-4);
  }
}

.orderedList {
  padding-left: var(--space-4);
  margin: 0;
  margin-left: var(--space-1);

  li {
    padding-left: var(--space-1);

    &::marker {
      color: var(--slate-9);
      font-size: var(--font-size-1);
      line-height: var(--line-height-1);
      font-weight: var(--font-weight-medium);
    }
  }
}

.compactOrderedList {
  display: flex;
  gap: var(--space-2);
  margin: 0;

  li {
    margin-right: var(--space-3);

    span {
      margin-right: var(--space-1);
    }

    &::marker {
      font-size: var(--font-size-1);
      line-height: var(--line-height-1);
      font-weight: var(--font-weight-medium);
    }
  }
}

.replyToChatPreview {
  width: 100%;

  .messageContainer {
    border-radius: var(--radius-3);
    border: 1px solid var(--slate-7);
    padding: var(--space-1);
    max-width: 80%;
    background: var(--slate-1);

    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &.clickable {
      &:hover {
        cursor: pointer;
        background: var(--indigo-a3);
      }
    }
  }
}

.mentionList {
  max-height: 40vh;
  max-width: 80%;
  width: calc(var(--space-9) * 6);
  border-radius: var(--radius-3);
  background-color: var(--white-1);
  box-shadow: var(--shadow-3);
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--space-2);
  display: flex;
  flex-direction: column;
  scroll-padding-block-start: var(--space-2);
  scroll-padding-block-end: var(--space-2);
  z-index: 100000;
}

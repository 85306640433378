.reactionTooltip {
  display: flex;
  flex-direction: column;
  background: var(--slate-a12);
  border-radius: var(--radius-3);
  padding: var(--space-2);
  max-width: 16rem;
  text-align: center;

  .arrow {
    fill: var(--slate-a12);
  }
}

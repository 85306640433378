@import "src/app/styles/mixings";

.similarThreadsSelector {
  padding: 0 var(--space-6) var(--space-3);
  display: flex;
  gap: var(--space-4);
  overflow-x: auto;
  padding-top: 1px; // fix for truncated card

  &:focus {
    outline: none;
  }

  .similarThreadButton {
    display: flex;
    border-radius: var(--radius-2);
    border: 1px solid var(--slate-a5);
    background: var(--slate-2);
    padding: var(--space-2);

    &:hover,
    &.selected {
      background: var(--white-1);
      padding: calc(var(--space-2) - 1px);

      .fileTextIcon,
      .arrowIcon {
        color: var(--indigo-8);
      }
    }

    .fileTextIcon {
      color: var(--slate-a8);
    }

    &:focus {
      outline: none;
    }

    .additionalInformation {
      margin-top: var(--space-4);
      width: 100%;

      display: flex;
      align-items: center;
      color: var(--slate-10);
    }
  }

  .selected {
    border: 2px solid var(--indigo-a8);
    box-shadow: var(--shadow-3);
  }

  .similarThreadModal {
    position: absolute;
    background: var(--color-panel-solid);
    padding: var(--space-4) var(--space-4);
    max-width: 85vw;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(var(--space-7) * 2);
    border-radius: var(--radius-4);
    z-index: 1000;
    box-shadow: var(--shadow-5);
    border: 2px solid var(--indigo-a8);

    @include respond-above(sm) {
      max-width: 43rem;
    }
  }
}

.fileUploadAnimation {
  span {
    opacity: 0.4;
    display: block;
    height: var(--space-2);
    width: var(--space-2);
    border-radius: 50%;
    background-color: var(--indigo-a10);
    margin-right: var(--space-2);
    animation: 1.5s blink infinite 0.3s;

    &:nth-of-type(1) {
      animation-delay: 0.6s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.9s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.2s;
    }
  }
}

@keyframes blink {
  15% {
    opacity: 1;
    transform: translateY(calc(var(--space-1) * -1));
  }
}

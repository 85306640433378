.bulletList {
  padding: 0;
  margin: 0;
  margin-left: var(--space-1);

  li {
    position: relative;
    margin-right: var(--space-2);
    padding-left: var(--space-5);

    &::before {
      content: "\2022"; /* Unicode character for bullet */
      position: absolute;
      left: 2px; // Manual fix so that it matches the ordered list
      color: var(--slate-9);
    }
  }
}

.compactBulletList {
  white-space: nowrap;
  list-style-type: "none";
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    position: relative;
    margin-right: var(--space-2);
    padding-left: var(--space-3);
    &::before {
      content: "\2022"; /* Unicode character for bullet */
      position: absolute;
      left: 0;
      color: var(--slate-9);
    }
  }
}

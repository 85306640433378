.chatBox {
  border-top: 1px solid var(--slate-a5);
  background: var(--slate-2);
  position: relative;

  .selectedFilesPreview {
    align-items: center;

    .removeFileButton {
      visibility: hidden;
      position: absolute;
      top: calc(var(--space-3) * -1);
      right: calc(var(--space-1) * -1);
      padding: var(--space-1);
    }

    .selectedFileContainer:hover {
      .removeFileButton {
        visibility: visible;
      }
    }
  }

  .mentionCallout {
    position: absolute;
    top: calc(var(--space-6) * -2);
    left: 50%;
    transform: translateX(-50%);
    background: var(--indigo-4);
  }

  .textAreaContainer {
    padding: var(--space-5) var(--space-6) var(--space-5);
    position: relative;

    @media (max-width: 768px) {
      padding: var(--space-3);
    }

    .buttonShelf {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--white-1);
      width: calc(100% - var(--space-5));
      margin: 0 auto;
      height: var(--space-7);
      position: absolute;
      bottom: 2px;
      left: 1px;
      border-bottom-left-radius: var(--radius-4);
      border-bottom-right-radius: var(--radius-4);
      z-index: 555;
      padding-left: var(--space-4);
      opacity: 1;
      transition: all 0.2s ease-in-out;

      .chatOptions {
        display: flex;
        align-items: center;
        gap: var(--space-3);
      }

      .chatSendAction {
        display: flex;
        align-items: center;

        &.disabled {
          pointer-events: none;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .collapsibleContent {
    overflow: hidden;
  }
  .collapsibleContent[data-state="open"] {
    animation: slideDown 150ms ease;
  }
  .collapsibleContent[data-state="closed"] {
    animation: slideUp 150ms ease;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-collapsible-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-collapsible-content-height);
    }
    to {
      height: 0;
    }
  }

  .similarThreadsContainer {
    border-top: 1px solid var(--slate-6);

    .similarThreadsTrigger {
      padding: var(--space-3) var(--space-6);

      .icon {
        color: var(--slate-a8);
        margin-right: var(--space-2);

        &.hasSimilarThreads {
          color: var(--slate-11);
        }
      }

      .text {
        color: var(--slate-10);

        &.hasSimilarThreads {
          color: var(--slate-11);
        }
      }

      &:hover {
        .icon {
          color: var(--accent-a8);
        }
        .text {
          color: var(--accent-a10);
        }
      }
    }
  }
}

.mainArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;

  .renderArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    background: var(--white-1);
    overflow-x: hidden;
  }
}

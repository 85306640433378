.codeBlockContainer {
  .header {
    background: var(--slate-5);
    border-radius: var(--radius-2) var(--radius-2) 0 0;
    padding: var(--space-1);
  }
  .codeBlock {
    margin: 0;
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  & span {
    overflow-wrap: break-word !important;
  }
}
